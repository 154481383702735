import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import checkAuth from "../middleware/checkAuth";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CountdownTimer from "../components/Exercice/CountDown";

const Exercice = () => {
	const [user, setUser] = useState(
		localStorage.user ? JSON.parse(localStorage.user) : null
	);
	const nav = useNavigate();
	const { programId, exerciceId, day, section } = useParams();
	const [program, setProgram] = useState({});
	const [exercice, setExercice] = useState({});
	const videoRef = useRef(null);
	const [time, setTime] = useState("00:00");
	const [isPlaying, setIsPlaying] = useState(false);
	const [duration, setDuration] = useState(0);
	const [progress, setProgress] = useState(0);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [doneSets, setDoneSets] = useState(0);
	const [showCounter, setShowCounter] = useState(true);
	const audioRef = useRef(null);

	const countDownFinich = () => {
		setShowCounter(false);
		if (videoRef.current) {
			videoRef.current.play().catch((error) => {
				console.log("Play failed:", error);
			});
			videoRef.current.currentTime = 0;
			setIsPlaying((prevState) => !prevState);
		}
		// togglePlay()
	};

	const togglePlay = () => {
		if (videoRef.current) {
			if (isPlaying) {
				videoRef.current.pause();
			} else {
				videoRef.current.play().catch((error) => {
					console.log("Play failed:", error);
				});
			}
			setIsPlaying((prevState) => !prevState);
		}
	};

	const getData = async () => {
		try {
			const resProgram = await axios.get(
				`${process.env.REACT_APP_API_URL}/api/program/${programId}`,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			setProgram(resProgram.data);

			const res = await axios.get(
				`${process.env.REACT_APP_API_URL}/api/exercice/${programId}/${exerciceId}`,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			setExercice(res.data);
			setDataLoaded(true);
		} catch (err) {
			toast.error(err.response.data.err ?? "Something wrong", {
				autoClose: 2000,
			});
		}
	};

	const handleTimeUpdate = () => {
		const currentTime = Math.floor(videoRef.current.currentTime);
		const minutes = Math.floor(currentTime / 60);
		const seconds = currentTime % 60;
		const formattedTime = `${String(minutes).padStart(2, "0")}:${String(
			seconds
		).padStart(2, "0")}`;
		setTime(formattedTime);

		if (duration) {
			const percentage = (currentTime / duration) * 100;
			setProgress(percentage);
		}
	};

	const handleLoadedMetadata = () => {
		setDuration(videoRef.current.duration);
	};

	const handleVideoEnd = async () => {
		try {
			setProgress(100);
			if (audioRef.current) {
				audioRef.current.play();
			}
			const res = await axios.post(
				`${process.env.REACT_APP_API_URL}/api/dailyActivity/${user._id}/${programId}/${section}/${day}/${exerciceId}`,
				{},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
					},
				}
			);
			toast(`Great`, {
				autoClose: 2000,
				onClose: () => {
					next();
				},
			});
		} catch (err) {
			toast.error(err.response.data.err ?? "Something wrong", {
				autoClose: 2000,
			});
		}
	};

	useEffect(() => {
		const authenticateAndSetupVideo = async () => {
			let isAuth = await checkAuth();
			if (isAuth) {
				await getData();
			} else {
				nav("/login");
				return;
			}
		};

		authenticateAndSetupVideo();
	}, [exerciceId]);

	useEffect(() => {
		const videoElement = videoRef.current;
		if (videoElement) {
			videoElement.addEventListener(
				"loadedmetadata",
				handleLoadedMetadata
			);
			// videoElement.addEventListener('timeupdate', handleTimeUpdate);
			videoElement.addEventListener("ended", handleVideoEnd);
		}

		// Cleanup function to remove event listeners on component unmount
		return () => {
			if (videoElement) {
				videoElement.removeEventListener(
					"loadedmetadata",
					handleLoadedMetadata
				);
				// videoElement.removeEventListener('timeupdate', handleTimeUpdate);
				videoElement.removeEventListener("ended", handleVideoEnd);
			}
		};
	}, [exercice, doneSets, videoRef]);

	const handleProgressClick = (e) => {
		const progressBar = e.currentTarget;
		const rect = progressBar.getBoundingClientRect(); // Get size & position of the progress bar
		const clickX = e.clientX - rect.left; // Get click position relative to the progress bar
		const newTime = (clickX / rect.width) * duration; // Calculate new time

		videoRef.current.currentTime = newTime; // Update video time
	};

	const goBack = () => {
		nav(`/days/${programId}/${section}`);
	};

	const next = () => {
		let index = parseInt(day) + 1;
		if (program?.calender[section].exercices.length - 1 < index) {
			nav(`/days/${programId}/${section}`);
		} else {
			window.location.hash = `#/exercice/${programId}/${section}/${index}/${program.calender[section].exercices[index].exercice}`;
			window.location.reload(); // Force page reload
		}
	};

	const back = () => {
		let index = parseInt(day) - 1;
		if (index == -1) {
			nav(`/days/${programId}/${section}`);
		} else {
			window.location.hash = `#/exercice/${programId}/${section}/${index}/${program.calender[section].exercices[index].exercice}`;
			window.location.reload(); // Force page reload
		}
	};

	return (
		<div
			className="relative max-w-[500px] min-h-[100vh] overflow-hidden  mx-auto bg-[#3D246B]"
			style={{}}
		>
			<audio ref={audioRef} src="./assets/no.wav" preload="auto" />
			<ToastContainer />
			{/* <div className='w-full h-[166px] absolute top-0 z-10 ' style={{ background: `linear-gradient(180deg, rgba(181,159,222,1) 8%, rgba(128,128,128,0) 77%)` }}>

            </div> */}

			<div className=" w-full h-auto bg-white opacity-90 relative">
				{/* <img
                    src={`${process.env.REACT_APP_API_URL}${exercice?.image?.path}`}
                    className={`w-full h-full object-fill ${showCounter ? 'block' : 'hidden'} absolute`}
                    alt="Exercise thumbnail"
                /> */}

				{dataLoaded ? (
					<video
						ref={videoRef}
						className="w-full aspect-video object-fill -z-20"
						controls={true}
						onTimeUpdate={handleTimeUpdate}
						onClick={togglePlay} // Toggle on click
						poster={`${process.env.REACT_APP_API_URL}/${exercice?.image?.path}`}
					>
						<source
							src={`${process.env.REACT_APP_API_URL}/${exercice.video.path}`}
							type="video/mp4"
						/>
						<p>Your browser does not support the video tag.</p>
					</video>
				) : (
					<p>Loading video...</p>
				)}
			</div>
			<div className=" absolute top-[30px] z-50 w-[90%] left-[50%] right-[50px] translate-x-[-50%]">
				<div className="w-full flex justify-between items-center">
					<button
						type="button"
						className="text-white w-[38px] h-[38px] rounded-full flex justify-center items-center bg-white  backdrop-blur-[30px] "
						onClick={goBack}
					>
						<img
							src="./assets/grey.png"
							alt="program"
							className="w-5 h-5 opacity-100 "
						/>
					</button>
					{/* <img src="./assets/tryfit-logo.png" alt="TryFit logo"  className="w-[96px] " />
                    <div></div> */}
				</div>
			</div>
			<div
				className=" bottom-0 z-50 w-full rounded-t-[30px] -mt-5 pb-4 "
				style={{
					background: `linear-gradient(180deg, rgba(83,49,146,1) 0%, rgba(59,35,104,1) 100%)`,
				}}
			>
				<div className="relative w-[90%] mx-auto  pt-9">
					{/* {showCounter ? <CountdownTimer seconds={doneSets == 0 ? 10 : exercice.rest} countDownFinich={countDownFinich} message={doneSets == 0 ? 'Get Ready!<br/> We will start in ' : 'Rest'} />
                        : <> */}
					{/* <div className='text-white flex justify-center items-center mt-3'>
                                <h1 className=' font-Montserrat text-3xl'>{time}</h1>
                            </div> */}

					{/* <div className='font-Montserrat text-white flex justify-between mt-3'>
                                <div>
                                    <p className='text-xl'>{exercice.sets}</p>
                                    <p className='tex-sm'>total sets</p>
                                </div>
                                <div>
                                    <p className='text-xl float-right'>{doneSets}</p>
                                    <p className='text-sm'>completed</p>
                                </div>
                            </div> */}
					{/* onClick={handleProgressClick} */}
					{/* <div className='w-full rounded-[20px] bg-[#B8A3E1] h-[9px] mt-4 mb-6' >
                                <div className='rounded-[20px] bg-white h-[9px] transition-all ease-in-out duration-300' style={{ width: `${progress}%` }}></div>
                            </div> */}
					<div className="flex justify-between items-center relative mt-5">
						<button
							className=" w-[41px] h-[41px] rounded-full bg-[#674C98] text-white inline-flex justify-center items-center"
							onClick={back}
						>
							<i className="fa-solid fa-arrow-left"></i>
						</button>
						<h1 className="font-bold font-euclid_circular text-white text-xl text-center">
							{program.calender &&
								program.calender[section].exercices &&
								program.calender[section].exercices[day].title}
						</h1>
						{/* <button className=' absolute left-[50%] right-[50%] translate-x-[-50%] top-[50%] bottom-[50%] translate-y-[-50%] flex justify-center items-center w-[61px] h-[61px] bg-[rgb(171,146,216)] text-white rounded-full border-8 border-[#674C98] ' onClick={togglePlay}>{!isPlaying ? <i className="fa-solid fa-play"></i> : <i className="fa-solid fa-pause"></i>}</button> */}
						<button
							className=" w-[41px] h-[41px] rounded-full bg-[#674C98] text-white inline-flex justify-center items-center"
							onClick={next}
						>
							<i className="fa-solid fa-arrow-right"></i>
						</button>
					</div>
					<p
						className="text-white font-euclid_circular mt-6"
						dangerouslySetInnerHTML={{
							__html: exercice.description,
						}}
					></p>

					{/* </> */}
					{/* } */}
				</div>
			</div>

			{/* Loading  */}
			{!dataLoaded && (
				<div
					className=" h-[100vh]  mx-auto flex justify-center items-center  fixed top-0 bottom-0  left-0 right-0 z-50 "
					style={{
						background: `linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(186,165,225,1) 0%, rgba(106,62,187,1) 100%)`,
					}}
					initial={{ opacity: 0, scale: 0.8 }}
					animate={{ opacity: 1, scale: 1 }}
					transition={{
						duration: 0.5,
						ease: "easeInOut",
						repeat: Infinity,
						repeatType: "reverse",
					}}
				>
					<div className="w-[90%] ">
						<div className="flex justify-center items-center">
							<img
								src="./assets/tryfit-logo.png"
								alt="TryFit logo"
								className="w-[480px]"
							/>
						</div>
						<div className="flex justify-center mt-[225px] mb-[175px]">
							<div className="spinner"></div>
						</div>
					</div>
				</div>
			)}
			{/* End Loading  */}
		</div>
	);
};

export default Exercice;
