import axios from 'axios'
import React , {useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'


const NavBar = () => {
    const [user, setUser] = useState(localStorage.user ? JSON.parse(localStorage.user) : null)
    const [lastRecord , setLastRecord ] = useState({})
    const nav = useNavigate()

    const getData = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/dailyActivity/lastRecord/${user._id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            console.log(res.data)
            setLastRecord(res.data)
        } catch (err) {
        }
    }

    useEffect(() => {
        getData()
    } , [])
    return (
        <div className='fixed bottom-0 left-0 right-0 bg-white rounded-t-[30px] h-[110px] z-50'>

            <Link to={"/program"} className='absolute left-[50%] right-[50%] translate-x-[-50%] -top-4 bg-[#E8DDFD] w-[70px] h-[70px] rounded-full flex justify-center items-center'><img src="./assets/Group 2379.png" alt="" /></Link>

            <ul className='w-[80%] mx-auto flex justify-between items-center mt-6 mb-9 '>
                <li className='flex justify-center items-center flex-col gap-[10px] text-base text-[#9EA1A7] font-semibold ' onClick={() => {
                    if(lastRecord)
                        nav(`/exercice/${lastRecord.programId}/${lastRecord.sectionId}/${lastRecord.day}/${lastRecord.exercices[0]}`)
                }} ><img src='./assets/grey.png' alt='program' className='w-5 h-5 ' />Program</li>
                <Link to={"/profile"} className='flex justify-center items-center flex-col gap-[10px] text-base text-[#9EA1A7] font-semibold'> <img src='./assets/user.png' alt="user" className='h-5 w-5' /> You</Link>
            </ul>
            {/* <div className=' absolute bottom-0 w-full h-[38px] flex justify-center items-center'>
                <div className='bg-[#5E37A6] rounded-full h-[6px] w-[148px]'>

                </div>
            </div> */}
        </div>
    )
}

export default NavBar