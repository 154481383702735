import React, { useEffect, useState, useRef } from 'react'
import NavBar from '../components/NavBar'
import { Link, useParams } from 'react-router-dom'
import checkAuth from '../middleware/checkAuth'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Loading from './Loading'
import Header from '../components/Header'


const Days = () => {
    const { programId, section, exercicesList } = useParams()
    const [user, setUser] = useState(localStorage.user ? JSON.parse(localStorage.user) : null)
    const nav = useNavigate()
    const [exercices, setExercices] = useState([])
    const [program, setProgram] = useState({})
    const [dataLoaded, setDataLoaded] = useState(false)
    const [dailyActivity, setDailyActivity] = useState({})
    const [activeSectionExercices, setActiveSectionExercices] = useState({})

    const getData = async () => {
        try {
            const resProgram = await axios.get(`${process.env.REACT_APP_API_URL}/api/program/${programId}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            setProgram(resProgram.data)
            const resActivity = await axios.get(`${process.env.REACT_APP_API_URL}/api/dailyActivity/${user._id}/monthActivity/${programId}/${section}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user.token}`
                }
            })
            setDailyActivity(resActivity.data)

            setDataLoaded(true)
        } catch (err) {
            console.log(err)
            toast.error(err.response.data.err ?? 'Something wrong', { autoClose: 2000 })
        }
    }



    useEffect(() => {
        const authentication = async () => {
            let isAuth = await checkAuth()
            if (isAuth) {
                getData()
            } else {
                nav('/login')
            }
        }
        authentication()

    }, [])


    const goBack = () => {
        nav(`/calender/${programId}`)
    }

    return (
        dataLoaded ? <div className="relative max-w-[500px] min-h-[100vh]  mx-auto  overflow-hidden pb-[100px]" style={{
        }}>
            <ToastContainer />

            <div className='absolute top-0 -z-10  w-full h-[50%] bg-white opacity-90'>
                <img src="./assets/victor-freitas-hOuJYX2K5DA-unsplash.jpg" alt="background" className=" w-full object-cover opacity-100 blur-lg -z-10 " />
            </div>
            <Header />
            <div className='w-full rounded-t-[30px] min-h-[100vh] mt-[24px] pb-8' style={{ background: `linear-gradient(180deg, rgba(184,163,224,1) 0%, rgba(113,71,190,1) 100%)` }}>
                <div className='w-[88%] mx-auto'>
                    <div className=' pt-[30px]' >
                        <h1 className='text-white font-euclid_circular font-bold text-[50px]'>Days</h1>
                        <div className='text-white flex justify-between items-center'>
                            <div>
                                <h3 className='text-xl'>{program.title} - {program.calender && program.calender[section].title}</h3>
                            </div>
                        </div>

                        <div className='mt-[30px] '>
                            {program.calender && program.calender[section].exercices && program.calender[section].exercices.map((elt, index) => <Link to={`/exercice/${programId}/${section}/${index}/${elt.exercice}`} className='block'>
                                <div key={index} className='h-[100px] bg-white rounded-3xl overflow-hidden flex gap-4 mb-5'>
                                    <div className='w-full pr-6 flex items-center'>
                                        <div className='font-Montserrat text-[#7F59C5] font-bold px-5 flex gap-2 items-center'>
                                            {dailyActivity && dailyActivity?.filter(dA => dA.sectionId == section && dA.day == index).length > 0 ?
                                                <button type='button' className='border-2 border-[#44CAAC] rounded-full w-5 h-5 text-xs text-[#44CAAC] flex justify-center items-center'>
                                                    <i className="fa-solid fa-check"></i>
                                                </button>
                                                : <button type='button' className='border-2 border-gray-300 rounded-full w-5 h-5 text-xs text-gray-300 flex justify-center items-center' >
                                                    {index + 1}
                                                </button>}
                                            {/* <h4>{exercices.find(elt1 => elt1.id == elt[0] ).title}</h4> */}
                                            <h4>{elt.title}</h4>
                                        </div>
                                    </div>
                                </div>
                            </Link>)}
                        </div>
                    </div>
                </div>
            </div>
            <NavBar />
        </div>
            : <Loading />
    )
}

export default Days